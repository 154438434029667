import React from "react";
import Icon from "../icons/icon";
import { NavLinkButton } from "../buttons/Buttons";

const button = {
  text: "View Project",
  classes: "bg-orange btn-md-m px-2 btn-all text-md-bold"
};
const FeaturedProjectItem = ({ project }) => (
  <div className="project-item-wrapper mb-4">
    <div className="project-image">
      <img
        src={project.image}
        alt={project.name}
        className="project-display-image bg-navy"
      />
    </div>
    <div className="project-text">
      <div className="top-desc-text">
        <p className="text-white-dull project-display-type sm-txt mb-0">
          {project.type}
        </p>
        <h3 className="project-display-name md-md-txt text-md-bold text-white">
          {project.name}
        </h3>
      </div>
      <div className="project-mini-desc">
        <h5 className="project-info-ttl text-md-bold text-white project-display-name">
          Project Info:
        </h5>
        <div className="mini-desc-text">
          <p className="mini-desc-item text-white">
            <Icon classes="fas fa-map-marked-alt txt-orange pr-2" />
            {project.location}
          </p>
          <p className="mini-desc-item text-white">
            <Icon classes="fas fa-coins txt-orange pr-2" />
            {project.budget}
          </p>
          <p className="mini-desc-item text-white project-display-client">
            <Icon classes="fas fa-user-tie txt-orange pr-2" />
            {project.client}
          </p>
          <NavLinkButton
            path={`/portfolio/projects/${project.id}?name=${project.path}`}
            text={button.text}
            classes={button.classes}
          />
        </div>
      </div>
    </div>
  </div>
);

export default FeaturedProjectItem;
