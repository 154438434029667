import React from "react";
import ServiceItem from "./ServiceItem";

import consultancy from "../../assets/icons/meeting.svg";
import building from "../../assets/icons/brickwall.svg";
import telecommunication from "../../assets/icons/satellite-dish.svg";
import water from "../../assets/icons/water.svg";
import civil from "../../assets/icons/civil-worker.svg";
import ict from "../../assets/icons/server.svg";

const services = [
  {
    name: "Building Works",
    icon: building,
    text:
      "ABMO LINKS has invested in personnel and equipment to handle " +
      "both major and minor building construction works including " +
      "but not limited to office fit-outs, renovation works, fence " +
      "construction, paint works and redecoration, " +
      "partitioning-merging and furniture installation works, and " +
      "excavation and concrete works."
  },
  {
    name: "Civil Works",
    icon: civil,
    text:
      "ABMO LINKS is well equipped to provide services in the civil " +
      " works segment covering rehabilitation works for sports’ " +
      "fields and football pitches, perimeter fencing and road " +
      "levelling works, routine maintenance, periodic " +
      "maintenance and spot improvement of earth roads, as " +
      "well as construction to bitumen standards."
  },
  {
    name: "Consultancy Services",
    icon: consultancy,
    text:
      "We offer our clients direct access to a team of highly " +
      "qualified and experienced specialists offering consultancy " +
      "services in preliminary work, building design, construction " +
      "management as well as construction supervision and " +
      "maintenance work, Project Management, Cost Control, " +
      "Quality and Safety Systems and also Environmental and" +
      " Social Impact."
  },
  {
    name: "Water Works",
    icon: water,
    text:
      "ABMO LINKS taps into the experience and expertise in water." +
      " We have engaged in offer services including construction of small " +
      "dams and  water pans, construction of masonry and elevated steel " +
      " tanks and their accessories, pipe laying: gravity main and " +
      " distribution mains, construction of standard water kiosks and" +
      " installation  with plastic tanks, installation of consumer water" +
      " meters, construction of valve chambers, rainwater harvesting and more."
    // " associated works, construction and rehabilitation of weirs " +
    // " and its accessories, dam and water pan de-silting and silt " +
    // " protection works, as well as construction of break pressure" +
    // "  tanks."
  },
  {
    name: "ICT Services",
    icon: ict,
    text:
      "ABMO LINKS’ developing Information and Communication " +
      "Technology presence is focused on providing prospective " +
      "clients with Microsoft Office, Windows and Server Licenses, " +
      "Network and Server Monitoring Software and Licenses, " +
      "Database Security Management Software and Licenses, " +
      "Force-point Data Protection Licenses, and also supply " +
      "delivery and installation of ICT Infrastructure Set-Up, ICT " +
      "Equipment, accessories, and consumables."
  },
  {
    name: "Telecommunication Services",
    icon: telecommunication,
    text:
      "ABMO LINKS’ growing investment in the telecommunication " +
      "space is positioning it be able to supply, install, test and " +
      "commission enterprise servers, power banks for server " +
      "rooms, fibre optic cables and accessories, structured " +
      "cables for WAN and LAN, IP PABX and related works, " +
      "and also Network Devices and Extension of Wi-Fi " +
      "coverage services. We also undertakes setting up, " +
      "re-modelling, interconnecting, modernization, and " +
      "maintenance of modular centres as well as Network " +
      "Upgrade works."
  }
];

const ServiceList = () => (
  <div className="default-y-spacing my-container service-list-container">
    <div className="services-row">
      {services.map(service => (
        <ServiceItem
          className="service-item"
          key={service.name}
          service={service}
        />
      ))}
    </div>
  </div>
);

export default ServiceList;
