import React from "react";
import { IconUnderLinedTitle } from "../titles/Titles";
import wIcon from "../../assets/icons/construction-worker.svg";
import ServiceHomeItem from "./ServiceHomeItem";

import meeting from "../../assets/icons/meeting.svg";
import brickWall from "../../assets/icons/brickwall.svg";
import satellite from "../../assets/icons/satellite-dish.svg";
import water from "../../assets/icons/water.svg";
import { NavLinkButton } from "../buttons/Buttons";

const items = [
  {
    text: "Building Works",
    icon: brickWall
  },
  {
    text: "Consultancy Services",
    icon: meeting
  },
  {
    text: "Water Works",
    icon: water
  },
  {
    text: "Telecommunication Services",
    icon: satellite
  }
];

const button = {
  path: "/services",
  text: "See All Our Services",
  classes: "bg-orange btn-md-m btn-all text-md-bold"
};

const HomeServices = () => (
  <div className="my-container default-y-spacing pt-5">
    <IconUnderLinedTitle
      text="Services We Offer Our Clients"
      icon={wIcon}
      lineCss="service-underline"
    />
    <div className="row">
      <div className="service-home-icon-wrapper mt-4 col-12">
        {items.map((item, i) => (
          <ServiceHomeItem icon={item.icon} text={item.text} key={i} />
        ))}
      </div>
      <div className="col-12 my-4 text-center">
        <NavLinkButton
          path={button.path}
          text={button.text}
          classes={button.classes}
        />
      </div>
    </div>
  </div>
);

export default HomeServices;
