import React from "react";
import { Link } from "react-router-dom";

export const NavLinkButton = ({ path, text, classes }) => (
  <Link
    to={path}
    className={`action-button ${classes}`}
    onClick={() => {
      const active = path.split("/")[1];
      document.querySelector(".nav-active").classList.remove("nav-active");
      document.querySelector("#" + active).classList.add("nav-active");
      window.scrollTo(0, 0);
    }}
  >
    {text}
  </Link>
);

export const ActionButton = ({ path, text, classes, action }) => (
  <Link to={path} className={`action-button ${classes}`} onClick={action}>
    {text}
  </Link>
);
