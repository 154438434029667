import React from "react";
import { NavLinkButton } from "../buttons/Buttons";
import { IconUnderLinedTitle } from "../titles/Titles";

import tIcon from "../../assets/icons/team.svg";

const HomeAboutLeft = ({ title, paragraphs, button }) => (
  <div className="home-about-item-wrapper pt-md-4">
    <div className="row">
      <div className="col-12 mb-3">
        <IconUnderLinedTitle
          text={title}
          icon={tIcon}
          lineCss="about-underline"
        />
      </div>
      <div className="col-12 mb-3">
        {paragraphs.map((paragraph, i) => (
          <p key={i} className={`${paragraph.classes}home-about-pars`}>
            {paragraph.text}
          </p>
        ))}
      </div>
      <div className="col-12 mb-3 text-center">
        <NavLinkButton
          path={button.path}
          text={button.text}
          classes={button.classes}
        />
      </div>
    </div>
  </div>
);

export default HomeAboutLeft;
