import React from "react";

import { IconUnderLinedTitle } from "../titles/Titles";
import FeaturedProjectItem from "./ProjectItem";

import dIcon from "../../assets/icons/skyline.svg";
import { NavLinkButton } from "../buttons/Buttons";

const button = {
  path: "/portfolio",
  text: "Visit Our Portfolio",
  classes: "bg-orange btn-md-m px-2 btn-all portfolio-view-btn text-md-bold"
};

const FeaturedProjects = ({ projects, portfolio }) => (
  <div className="my-container project-display-container default-y-spacing ">
    <IconUnderLinedTitle
      text="Our featured Projects"
      icon={dIcon}
      lineCss="project-underline"
    />
    <div className="project-display-wrapper mt-md-4 mt-3">
      {projects.map(project => (
        <FeaturedProjectItem project={project} key={project.name} />
      ))}
    </div>
    {!portfolio && (
      <div className="line-and-button">
        <div className="muted-straight-line"></div>
        <NavLinkButton
          path={button.path}
          text={button.text}
          classes={button.classes}
        />
      </div>
    )}
  </div>
);

export default FeaturedProjects;
