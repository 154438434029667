import React, { Component } from "react";

import Aux from "../../hoc/Auxil";
import TopBar from "../../components/topBar/TopBar";
import WeUnique from "../../components/weUnique/WeUnique";
// import Testimonial from "../../components/testimonials/Testimonials";
import QuotePortfolio from "../../components/about/QuotePortfolio";
import Team from "../../components/team/Team";
import Partners from "../../components/partners/Partners";

class About extends Component {
  render() {
    return (
      <Aux>
        <TopBar imageId="about-bg" text="About Us" />
        <div className="about-nca-text-wrap default-y-spacing my-container">
          <p className="nca-text mb-3 text-bold text-sm-bold">
            In the National Construction Authority’s (NCA) Work-head, ABMO LINKS
            LIMITED is graded NCA 2 for General Building Works, NCA 5 for Civil
            Works, and NCA 5 for Water Works.
          </p>
          <div className="m-0 nca-text">
            ABMO LINKS Limited has attained the Safety and Site Management
            Certificates, it signifies the company’s commitment to continuously
            provide the best services and also to deliver high quality standards
            to its customers. It is our policy to ensure timely handover and
            great quality.
          </div>
        </div>
        <WeUnique />
        {/* <Testimonial /> */}
        <Team />
        <QuotePortfolio />
        <Partners />
      </Aux>
    );
  }
}

export default About;
