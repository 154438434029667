import React from "react";
import { Link } from "react-router-dom";

const NavItem = ({ path, name, click }) => (
  <li className="nav-item">
    <Link className="nav-link" to={path} name={name} id={name} onClick={click}>
      {" "}
      {name}{" "}
    </Link>
  </li>
);

export default NavItem;
