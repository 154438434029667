import React from "react";
import { IconUnderLinedTitle } from "../titles/Titles";

import cIcon from "../../assets/icons/customer-service.svg";

const QuoteTextNext = ({ title, paragraphs }) => (
  <div className="home-about-item-wrapper pt-md-4">
    <div className="row">
      <div className="col-12 mb-2">
        <IconUnderLinedTitle
          text={title}
          classes="m-0"
          icon={cIcon}
          lineCss="home-quote-underline"
        />
      </div>
      <div className="col-12 mb-3">
        {paragraphs.map((paragraph, i) => (
          <p key={i} className={`${paragraph.classes}home-about-pars`}>
            {paragraph.text}
          </p>
        ))}
      </div>
    </div>
  </div>
);

export default QuoteTextNext;
