import React from "react";

const Icon = ({ classes, click }) => (
  <i
    className={classes}
    onClick={click ? e => click(e) : e => e.preventDefault()}
  />
);

export const SvgIcon = ({ src, classes, alt }) => (
  <img src={src} className={classes} alt={alt} />
);

export const SvgIconMiniText = ({
  src,
  iconClasses,
  alt,
  text,
  textClasses
}) => (
  <div className="my-2">
    <SvgIcon src={src} classes={iconClasses} alt={alt} />
    <span className={`mini-icon-text ${textClasses}`}>{text}</span>
  </div>
);
export default Icon;
