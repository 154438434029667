import React from "react";
import { SvgIcon } from "../icons/icon";

const IconUnderline = ({ icon, lineCss }) => (
  <div className="icon-underline-wrapper">
    <div className={`underline-row ${lineCss}`}>
      <div className="underline-line bg-navy" />
      <SvgIcon src={icon} alt={icon} classes="underline-icon" />
      <div className="underline-line bg-navy" />
    </div>
  </div>
);

export default IconUnderline;
