import React from "react";
import { Redirect } from "react-router-dom";

import Aux from "../../hoc/Auxil";

import projects from "./projectData";
import { IconUnderLinedTitle } from "../../components/titles/Titles";
import TopBar from "../../components/topBar/TopBar";
import iIcon from "../../assets/icons/image.svg";
import dIcon from "../../assets/icons/project-details.svg";
import ProjectImages from "../../components/projects/ProjectImages";
import ProjectDetails from "../../components/projects/ProjectDetails";

class Project extends React.Component {
  state = {
    project: {},
    invalid: false,
    next: 0,
    prev: 0
  };
  componentDidMount() {
    let projectId = window.location.href.split("/")[5].split("?")[0];
    try {
      projectId = parseInt(projectId);
    } catch (error) {
      projectId = 0;
    }

    if (projectId < 1 || projectId > projects.length) {
      this.setState({ invalid: true });
    }

    const project = projects[projectId - 1];
    if (projectId > 1 && projectId < projects.length) {
      this.setState({ next: projectId + 1, prev: projectId - 1 });
    } else if (projectId === 1) {
      this.setState({ next: projectId + 1, prev: 0 });
    } else if (projectId === projects.length) {
      this.setState({ next: 0, prev: projectId - 1 });
    }
    this.setState({ project });
  }

  switchProject = id => {
    window.location.href = `/portfolio/projects/${id}?name=${
      projects[id - 1].path
    }`;
  };
  render() {
    const { invalid, project, next, prev } = this.state;
    return (
      <Aux>
        {invalid && <Redirect to="/portfolio" />}
        {!(
          Object.keys(project).length === 0 && project.constructor === Object
        ) && (
          <>
            <TopBar imageId="portfolio-bg" text={project.name} />
            <div className="my-container project-container default-y-spacing">
              <div className="w-100 mb-md-5 mb-4">
                <IconUnderLinedTitle
                  text={"Project Details"}
                  icon={dIcon}
                  lineCss="project-underline"
                />
              </div>
              <ProjectDetails project={project} />
            </div>
            <div className="my-container default-y-spacing">
              <div className="w-100 mb-md-5 mb-4">
                <IconUnderLinedTitle
                  text={"Project Images"}
                  icon={iIcon}
                  lineCss="project-underline"
                />
              </div>
              <ProjectImages images={project.images} />
              <div className="mt-md-5 mt-4 project-controls">
                {prev > 0 && (
                  <div
                    className="project-control-arrow txt-orange"
                    onClick={() => this.switchProject(prev)}
                  >
                    <span className="fas fa-arrow-left txt-orange pr-md-3 pr-1 txt-sm-sm" />
                    Previous Project
                  </div>
                )}
                {next > 0 && (
                  <div
                    className="project-control-arrow project-control-arrow-right txt-orange"
                    onClick={() => this.switchProject(next)}
                  >
                    Next Project
                    <span className="fas fa-arrow-right txt-orange pl-md-3 pl-1 txt-sm-sm" />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Aux>
    );
  }
}

export default Project;
