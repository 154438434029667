import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import Aux from "../../hoc/Auxil";
import LandingPage from "../../components/landingPage/LandingPage";
import WorkStats from "../../components/workStats/WorkStats";
import HomeAbout from "../../components/homeAbout/HomeAbout";
import HomeServices from "../../components/servicesHome/ServicesHome";
import FeaturedProjects from "../../components/featuredProjects/Projects";
import HomeQuote from "../../components/homeQuote/HomeQuote";

import Tomlo from "../../assets/images/projects/display/tomlo.jpg";
import Simiyus from "../../assets/images/projects/display/simiyu.jpg";

const projects = [
  {
    id: 1,
    image: Tomlo,
    name: "Tolmo Water Project",
    type: "Water Works",
    budget: "5.22M",
    location: "Tolmo, Baringo County, Kenya",
    client: "Rift Valley Water Services Board",
    path: "Tomlo-Water-Project"
  },
  {
    id: 2,
    image: Simiyus,
    name: "The Simiyu's Residential Bungalow",
    type: "Building and Construction Works",
    budget: "17.0M",
    location: "Kwanza , Trans Nzoia County, Kenya",
    client: "Mr. & Mrs. Simiyu",
    path: "The-Simiyus-Residential-Bungalow "
  }
];

class Home extends Component {
  componentDidMount() {
    if (this.recaptcha) {
      this.restartCaptcha();
    }
  }

  restartCaptcha = () => {
    this.recaptcha.reset();
  };
  render() {
    return (
      <Aux className="home">
        <LandingPage />
        <WorkStats />
        <HomeAbout />
        <HomeServices />
        <FeaturedProjects projects={projects} portfolio={false} />
        <HomeQuote recaptcha={this.recaptcha} />
        <ReCAPTCHA
          ref={ref => (this.recaptcha = ref)}
          sitekey={process.env.REACT_APP_V2_CAPTCHA_SITE_KEY}
          size="invisible"
          render="explicit"
          onExpired={this.restartCaptcha}
        />
      </Aux>
    );
  }
}

export default Home;
