import React, { Component } from "react";
import Aux from "../../hoc/Auxil";
import FooterItem from "../../components/footer/FooterItem";
import FooterItemLeft from "../../components/footer/FooterItemLeft";
import FooterItemMiddle from "../../components/footer/FooterItemMiddle";
import FooterItemRight from "../../components/footer/FooterItemRight";

class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <Aux>
        <div className="bg-navy main-footer py-5 px-3" id="main-footer">
          <div className="footer-row">
            <div className="footer-motto footer-item-wrapper">
              <FooterItem
                item={{ component: FooterItemLeft }}
                itemClasses="pl-md-4 footer-left"
              />
            </div>
            <div className="footer-contact footer-item-wrapper">
              <FooterItem
                item={{ component: FooterItemMiddle }}
                itemClasses="footer-right"
              />
              <FooterItem
                item={{ component: FooterItemRight }}
                itemClasses="footer-center"
              />
            </div>
          </div>
          <div className="copyright text-white-dull">
            Copyright ©{year} All rights reserved | Designed and Created by{" "}
            <a
              href="https://modtechy.com"
              target="_blank"
              rel="noopener noreferrer"
              className="external-link techy-blue"
            >
              modtechy.com
            </a>
          </div>
        </div>
      </Aux>
    );
  }
}

export default Footer;
