import React from "react";
import StatItem from "./StatItem";

import Clients from "../../assets/icons/clients-bg.svg";
import Locations from "../../assets/icons/map-bg.svg";
import BluePrint from "../../assets/icons/blueprint-bg.svg";
import { IconUnderLinedTitle } from "../titles/Titles";

import tIcon from "../../assets/icons/statistical.svg";

const stats = [
  {
    name: "clients-stats",
    text: " happy clients",
    number: 20,
    icon: Clients
  },
  {
    name: "counties",
    text: " counties",
    number: 10,
    icon: Locations
  },
  {
    name: "blueprints",
    text: " projects",
    number: 20,
    icon: BluePrint
  }
];
const WorkStats = () => (
  <div className="stats-wrapper my-container default-y-spacing animated bounceIn">
    <IconUnderLinedTitle text="Let The Numbers Speak For Us" icon={tIcon} />
    <div className="row mt-md-5 mt-3">
      {stats.map(stat => (
        <div className="col text-center mb-2" key={stat.name}>
          <StatItem
            icon={stat.icon}
            text={stat.text}
            alt={stat.name}
            number={stat.number}
          />
        </div>
      ))}
    </div>
  </div>
);

export default WorkStats;
