import React from "react";

import NavItem from "./NavItem";
import Icon from "../icons/icon";

const navigation = [
  {
    name: "home",
    path: "/home",
    active: true
  },
  {
    name: "about",
    path: "/about"
  },
  {
    name: "services",
    path: "/services"
  },
  {
    name: "portfolio",
    path: "/portfolio"
  },
  {
    name: "contact",
    path: "/contact"
  }
];

const Navbar = ({ navLinkClick, barsIconClick }) => (
  <div className="navbar-wrapper  h-100">
    <Icon
      classes="fas fa-bars text-white phone-nav-icon icon"
      click={barsIconClick}
    />
    <nav className="main-nav animated-display" id="main-nav">
      <ul className="nav-item-wrap">
        {navigation.map(nav => (
          <NavItem
            key={nav.path}
            path={nav.path}
            name={nav.name}
            active={nav.active}
            click={navLinkClick}
          />
        ))}
      </ul>
    </nav>
  </div>
);

export default Navbar;
