import React from "react";
import { SvgIconMiniText } from "../icons/icon";

import phoneIcon from "../../assets/icons/phone-call.svg";
import emailIcon from "../../assets/icons/email.svg";
import Title from "../titles/Titles";

const phones = ["+2547 33 363 373", "+2547 22 927 860", "+2547 22 425 560"];
const emails = ["info@abmolinks.co.ke", "abmolinks@gmail.com"];

const FooterItemMiddle = () => (
  <>
    <div className="phones">
      <Title text="Call Us" classes="text-white footer-titles" />
      {phones.map(phone => (
        <SvgIconMiniText
          src={phoneIcon}
          text={phone}
          textClasses="txt-orange sm-txt pl-3"
          iconClasses="footer-item-icon"
          alt={phone}
          key={phone}
        />
      ))}
    </div>
    <div className="email">
      <Title text="Email Us" classes="text-white footer-titles" />
      {emails.map(email => (
        <SvgIconMiniText
          src={emailIcon}
          text={email}
          textClasses="txt-orange sm-txt pl-3"
          iconClasses="footer-item-icon"
          alt={email}
          key={email}
        />
      ))}
    </div>
  </>
);

export default FooterItemMiddle;
