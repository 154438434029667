import React from "react";

import UniqueItem from "./Unique";
import { IconUnderLinedTitle } from "../titles/Titles";

import deliveryIcon from "../../assets/icons/rocket.svg";
import profIcon from "../../assets/icons/bag.svg";
import customerSIcon from "../../assets/icons/conversation.svg";
import rankIcon from "../../assets/icons/hierarchy.svg";
import commitIcon from "../../assets/icons/certificate.svg";
import revIcon from "../../assets/icons/happy-review.svg";
import conIcon from "../../assets/icons/confetti.svg";

const uniqueItems = [
  {
    icon: deliveryIcon,
    text:
      "We acknowledges that good client relationship is hinged not only on quality but on timely delivery of project milestones. To achieve this, we maintain accurate inventory records, create and maintain realistic project schedules, monitor efficiency, and build strong relationships with suppliers.",
    title: "On Time Delivery"
  },
  {
    icon: profIcon,
    text:
      "We considers employee interactions and relationships with its clients of vital importance in meeting its goals and objectives. We therefore create a culture that encourages and promotes excellence, trustworthiness, courtesy, honesty, transparency, competency, integrity, confidentiality, and leading by own example.",
    title: "Professional service"
  },
  {
    icon: customerSIcon,
    text:
      "We believes that at the heart of its growth and development, are existing and potential clients and customers. We thus endeavour to know what our customers consider quality service, take time to find out our customers’ expectations, follow up on both positive and negative feedback we receive, and continuously look for ways to improve the level of customer service we deliver.",
    title: "Quality Customer Service"
  },
  {
    icon: rankIcon,
    text:
      "We recognizes that a well-trained and experienced workforce is the only source of effectiveness, efficiency, and sustainability. We thus ensure that our workforce well understand both the organization’s and project goals, focusing their energy on tasks that support the goals while ensuring that they are good at it in their own unique and lasting way..",
    title: "Well Managed Workforce"
  },
  {
    icon: commitIcon,
    text:
      "As ABMO LINKS, we are committed to ensuring that you, our clients and customers, remain the focal point of everything we do. This commitment means competence, discretion, transparency, integrity, and value; riding on a well managed workforce, on time delivery, professional service, and quality customer service.",
    title: "Our Commitment"
  },
  {
    icon: revIcon,
    text:
      "We strive to give the best to our clients here at ABMO LINKS. We have done and will keep doing our best to leave our clients happy. Being our client, your happiness is our satisfaction. Below are some testimonies from former clients we have worked with. You could be one of them by just reaching out to us.",
    title: "Positive Client Reviews"
  }
];

const WeUnique = () => (
  <div className="default-y-spacing p-2 we-unique-wrapper">
    <div className="mb-4">
      <IconUnderLinedTitle
        text="What Makes Us Unique"
        icon={conIcon}
        lineCss="unique-title-underline"
      />
    </div>
    <div className="about-us-unique">
      {uniqueItems.map(item => (
        <UniqueItem item={item} key={item.title} />
      ))}
    </div>
  </div>
);

export default WeUnique;
