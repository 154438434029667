import React from "react";

import { SvgIcon } from "../icons/icon";

const ServiceHomeItem = ({ icon, text }) => (
  <div className="stat-icon-wrapper text-center service-home-item-icon">
    <SvgIcon src={icon} classes="service-icon" />
    <p className="service-home-item-text txt-navy md-txt text-capitalize">
      {text}
    </p>
  </div>
);

export default ServiceHomeItem;
