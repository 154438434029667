import React, { Component } from "react";
import validator from "validator";
import * as emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

import Aux from "../../hoc/Auxil";
import TopBar from "../../components/topBar/TopBar";
import Title from "../../components/titles/Titles";

class Contact extends Component {
  state = {
    firstName: "",
    firstNameError: false,
    firstNameErrorMsg: "Enter A Valid Name",
    lastName: "",
    lastNameError: false,
    lastNameErrorMsg: "Enter A Valid Name",
    email: "",
    emailError: false,
    emailErrorMsg: "Enter A Valid Email Address",
    description: "",
    descriptionError: false,
    descriptionErrorMsg:
      "A description cannot be less than 200 characters Long",
    formValid: false,
    buttonText: " SEND YOUR QUOTE",
    submitted: false,
    failed: false,
    showResponse: false
  };

  componentDidMount() {
    if (this.recaptcha) {
      this.restartCaptcha();
    }
  }

  restartCaptcha = () => {
    this.recaptcha.reset();
  };
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.handleBlur(e);
  };

  validateInput = () => {
    const { firstName, lastName, email, description } = this.state;

    if (firstName && email && lastName && description) {
      this.setState({ formValid: true });
    } else {
      this.setState({ formValid: false });
    }
  };

  handleBlur = e => {
    const currentField = e.target.name;
    const currentFieldValue = e.target.value.trim();
    let invalid = false;
    if (
      (currentField === "firstName" || currentField === "lastName") &&
      !validator.isLength(currentFieldValue, { min: 3, max: 50 })
    ) {
      invalid = true;
    } else if (
      currentField === "email" &&
      !validator.isEmail(currentFieldValue)
    ) {
      invalid = true;
    } else if (
      currentField === "description" &&
      !validator.isLength(currentFieldValue, { min: 200, max: 1000 })
    ) {
      invalid = true;
      if (currentFieldValue.length > 1000) {
        this.setState({
          descriptionErrorMsg:
            "You have exceeded text limit. Consider sending an Email instead"
        });
      } else {
        this.setState({
          descriptionErrorMsg:
            "A description cannot be less than 200 characters Long"
        });
      }
    }

    const errorField = currentField + "Error";
    if (invalid) {
      this.setState({ [errorField]: true });
    } else {
      this.setState({ [errorField]: false });
    }
    this.validateInput();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ buttonText: "sending...." });

    const { firstName, email, lastName, description } = this.state;
    e.preventDefault();

    const template_params = {
      name: `${firstName} ${lastName}`,
      email,
      description
    };
    const user_id = process.env.REACT_APP_EMAILJS_USER_ID;
    const service_id = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const template_id = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    this.recaptcha.execute();
    this.setState({ formValid: false });
    if (this.recaptcha.getValue()) {
      emailjs
        .send(service_id, template_id, template_params, user_id)
        .then(res => {
          if (res.status === 200) {
            this.setState({ submitted: true, buttonText: "SEND YOUR QUOTE" });
            this.recaptcha.reset();
            this.setState({
              email: "",
              description: "",
              firstName: "",
              lastName: ""
            });
          }
        })
        .catch(() => {
          this.recaptcha.reset();
          this.setState({
            failed: true,
            email: "",
            buttonText: "SEND YOUR QUOTE"
          });
        });
    }

    this.setState({ showResponse: true }, () => {
      setTimeout(() => {
        this.setState({
          showResponse: false,
          failed: false,
          submitted: false
        });
      }, 10000);
    });
  };

  render() {
    const {
      firstName,
      firstNameError,
      firstNameErrorMsg,
      lastName,
      lastNameError,
      lastNameErrorMsg,
      email,
      emailErrorMsg,
      emailError,
      description,
      descriptionErrorMsg,
      descriptionError,
      formValid,
      buttonText,
      showResponse,
      failed,
      submitted
    } = this.state;
    return (
      <Aux>
        <TopBar imageId="contact-bg" text="Contact Us" />
        <Title
          text="Schedule an estimate. Let’s work together"
          classes="txt-orange lg-txt my-md-4 mt-4 contact-title"
        />
        <div className="my-md-5 my-4">
          <form className="contact-form" onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="form-group col-md-6 col-12">
                <input
                  type="text"
                  name="firstName"
                  value={firstName}
                  className="form-control"
                  placeholder="First Name"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
                {firstNameError && (
                  <span className="error text-danger">
                    {firstNameErrorMsg}{" "}
                  </span>
                )}
              </div>
              <div className="form-group col-md-6 col-12">
                <input
                  type="text"
                  name="lastName"
                  value={lastName}
                  className="form-control"
                  placeholder="Last Name"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
                {lastNameError && (
                  <span className="error text-danger">{lastNameErrorMsg} </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-12">
                <input
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Email Address"
                  className="form-control"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
                {emailError && (
                  <span className="error text-danger">{emailErrorMsg} </span>
                )}
              </div>
            </div>
            <div>
              <div className="row">
                <div className="form-group col-12">
                  <textarea
                    name="description"
                    value={description}
                    id="quote-description"
                    rows="10"
                    className="form-control"
                    placeholder="Add Your Message"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  ></textarea>
                  {descriptionError && (
                    <span className="error text-danger">
                      {descriptionErrorMsg}{" "}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="form-group col-12">
                  <button
                    className="action-button btn-md-m bg-orange text-white text-bold"
                    type="submit"
                    disabled={!formValid}
                  >
                    {buttonText}
                  </button>
                </div>
              </div>
            </div>
            <ReCAPTCHA
              ref={ref => (this.recaptcha = ref)}
              sitekey={process.env.REACT_APP_V2_CAPTCHA_SITE_KEY}
              size="invisible"
              render="explicit"
              onExpired={this.restartCaptcha}
            />
            {showResponse && (
              <div
                className={`col-12 text-white text-center py-2 ${
                  failed ? "bg-danger" : ""
                } ${submitted ? "bg-success" : ""}`}
              >
                {submitted
                  ? "Your Quote was successfully sent."
                  : failed
                  ? "Sorry we were unable to send your quote. Please check your internet and try again."
                  : ""}
              </div>
            )}
          </form>
        </div>
      </Aux>
    );
  }
}

export default Contact;
