import React from "react";
import { SvgIconMiniText } from "../icons/icon";

const UniqueItem = ({ item }) => (
  <div className="about-unique-item bg-navy p-4 mb-md-5 mb-3">
    <div className="unique-head-box mb-2">
      <SvgIconMiniText
        iconClasses="about-unique-icon"
        src={item.icon}
        alt={item.title}
        text={item.title}
        textClasses="unique-head-text txt-orange ml-2"
      />
    </div>
    <div className="unique-text text-thin text-white sm-txt">{item.text}</div>
  </div>
);

export default UniqueItem;
