import React, { Component } from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";

import "../assets/scss/index.css";

import Home from "../Containers/home/Home";
import Header from "../Containers/header/Header";
import Footer from "../Containers/footer/Footer";
import About from "../Containers/about/About";
import Services from "../Containers/services/Services";
import Portfolio from "../Containers/portfolio/Portfolio";
import Contact from "../Containers/contact/Contact";
import Project from "../Containers/portfolio/Project";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="bg-blur animated-display" id="bg-blur" />
        <Header />
        <Route exact path="/" component={ToHome} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route path="/portfolio/projects/:id" component={Project} />
        <Route exact path="/contact" component={Contact} />
        <Footer />
      </BrowserRouter>
    );
  }
}

const ToHome = () => <Redirect to="/home" />;
export default App;
