import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { IconUnderLinedTitle } from "../titles/Titles";

import Kiyan from "../../assets/images/partners/part-1.jpg";
import Dutton from "../../assets/images/partners/part-2.jpg";
import MoRapid from "../../assets/images/partners/part-3.jpg";
import sIcon from "../../assets/icons/hand-shake-ii.svg";

const partners = [
  {
    name: "KIYAN GROUP LIMITED",
    image: Kiyan,
    website: "https://kiyan.co.ke/",
    imgCls: "kiyan-logo"
  },
  {
    name: "DUTTON LIMITED",
    image: Dutton,
    website: "",
    imgCls: "dutton-logo"
  },
  {
    name: "MO-RAPID SOLUTIONS",
    image: MoRapid,
    website: "https://morapid.co.ke/",
    imgCls: "mo-rapid-logo"
  }
];

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  pauseOnHover: true,
  swipeToSlide: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 414,
      settings: {
        slidesToShow: 2
      }
    }
  ]
};

const Partners = () => (
  <div className="default-y-spacing my-container">
    <IconUnderLinedTitle
      icon={sIcon}
      text="Our Partners"
      lineCss="team-member-underline"
    />
    <div className="partners-slider-wrapper mt-md-5 mt-4">
      <Slider {...settings}>
        {partners.map(partner => (
          <div
            className="partner-image-box col text-center p-3"
            title={partner.name}
            key={partner.name}
            onClick={e => {
              e.preventDefault();
              if (partner.website) {
                window.open(partner.website, "_blank");
              }
            }}
          >
            <img
              src={partner.image}
              alt={partner.name}
              className={`partner-image-box img-fluid ${partner.imgCls}`}
            />
          </div>
        ))}
      </Slider>
    </div>
  </div>
);

export default Partners;
