import React from "react";

const ServiceItem = ({ service }) => (
  <div className="service-item">
    <div className="service-top text-center">
      <img src={service.icon} alt={service.name} className="service-icon" />
      <h5 className="service-name">{service.name}</h5>
    </div>
    <div className="service-text">{service.text}</div>
  </div>
);

export default ServiceItem;
