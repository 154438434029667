import React from "react";
import Aux from "../../hoc/Auxil";
import IconUnderline from "../lines/IconUnderline";

const Title = ({ text, classes }) => (
  <h2 className={`default-title text-bold ${classes}`}>{text}</h2>
);

export const IconUnderLinedTitle = ({ text, icon, lineCss }) => (
  <Aux>
    <Title text={text} classes="" />
    <IconUnderline icon={icon} lineCss={lineCss} />
  </Aux>
);

export default Title;
