import React from "react";
import { NavLinkButton } from "../buttons/Buttons";

const QuotePortfolio = () => (
  <div className="default-y-spacing my-container p-q-wrapper">
    <div className="p-q-row">
      <div className="portfolio-about bg-navy p-q-item p-md-5 py-5 px-4 mb-4">
        <p className="about-p-q-text text-white mb-4">
          {/* Do you want to look at our work? <br /> */}
          Have a look at our portfolio and see some of our top featured projects
        </p>
        <div className="button-wrapper">
          <NavLinkButton
            text="Visit Our Portfolio"
            classes="text-white bg-orange btn-lg-l p-q-button"
            path="/portfolio"
          />
        </div>
      </div>
      <div className="quote-about bg-navy p-q-item p-md-5 py-5 px-4">
        <p className="about-p-q-text text-white mb-4">
          {/* Have you been impressed this far by us? <br /> */}
          Let's start talking about dream project. We can make it a reality
          together.
        </p>
        <div className="button-wrapper">
          <NavLinkButton
            text="Get A Quote"
            classes="text-white bg-orange p-q-button  btn-lg-l"
            path="/contact"
          />
        </div>
      </div>
    </div>
  </div>
);

export default QuotePortfolio;
