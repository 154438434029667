import React, { Component } from "react";
import Aux from "../../hoc/Auxil";
import TopBar from "../../components/topBar/TopBar";
import ServiceList from "../../components/services/ServiceList";
import { NavLinkButton } from "../../components/buttons/Buttons";

class Services extends Component {
  render() {
    return (
      <Aux>
        <TopBar imageId="services-bg" text="Our Services" />
        <div className="about-nca-text-wrap default-y-spacing my-container services-into-text">
          <div className="m-0 nca-text">
            Having been in the construction industry for a couple of years this
            far. We have ended up engaging in a couple of activities. We offer a
            variety of services within our main categories of Building and
            Construction, Road Construction and Maintenance, Water Supply and
            Also in Telecommunication. Below is a more comprehensive list of
            these.
          </div>
        </div>
        <ServiceList />
        <div className="service-port-btn-wrapper text-center default-y-spacing">
          <NavLinkButton
            text="Have A Look At Our Portfolio"
            path="/portfolio"
            classes="text-white bg-orange btn-lg-l md-txt text-bold"
          />
        </div>
      </Aux>
    );
  }
}

export default Services;
