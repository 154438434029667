import React from "react";

import { SvgIcon } from "../icons/icon";

const StatItem = ({ icon, text, number }) => (
  <div className="stat-icon-wrapper">
    <SvgIcon src={icon} classes="stat-icon" />
    <p className="stat-text txt-navy md-md-txt text-bold text-capitalize">
      <span className="stat-plus  txt-orange md-md-txt text-bold">
        {" "}
        {number}+{" "}
      </span>
      {text}
    </p>
  </div>
);

export default StatItem;
