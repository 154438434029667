import React, { Component } from "react";
import validator from "validator";
import * as emailjs from "emailjs-com";

const button = {
  path: "/home",
  text: "GET A QUOTE",
  classes: "bg-orange btn-md-m btn-all text-md-bold quote-btn-home"
};

class QuoteForm extends Component {
  state = {
    name: "",
    nameError: false,
    nameErrorMsg: "Enter A Valid Name",
    email: "",
    emailError: false,
    emailErrorMsg: "Enter A Valid Email Address",
    description: "",
    descriptionError: false,
    descriptionErrorMsg:
      "A description cannot be less than 200 characters Long",
    formValid: false,
    buttonText: "GET A QUOTE",
    submitted: false,
    failed: false,
    showResponse: false
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.handleBlur(e);
  };

  validateInput = () => {
    const { name, email, description } = this.state;

    if (name && email && description) {
      this.setState({ formValid: true });
    } else {
      this.setState({ formValid: false });
    }
  };

  handleBlur = e => {
    const currentField = e.target.name;
    if (e.target.value === undefined) {
      return 0;
    }
    const currentFieldValue = e.target.value.trim();
    let invalid = false;
    if (
      currentField === "name" &&
      !validator.isLength(currentFieldValue, { min: 3, max: 50 })
    ) {
      invalid = true;
    } else if (
      currentField === "email" &&
      !validator.isEmail(currentFieldValue)
    ) {
      invalid = true;
    } else if (
      currentField === "description" &&
      !validator.isLength(currentFieldValue, { min: 200, max: 1000 })
    ) {
      invalid = true;
      if (currentFieldValue.length > 1000) {
        this.setState({
          descriptionErrorMsg:
            "You have exceeded text limit. Consider sending an Email instead"
        });
      } else {
        this.setState({
          descriptionErrorMsg:
            "A description cannot be less than 200 characters Long"
        });
      }
    }

    const errorField = currentField + "Error";
    if (invalid) {
      this.setState({ [errorField]: true });
    } else {
      this.setState({ [errorField]: false });
    }
    this.validateInput();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ buttonText: "sending...." });

    const { name, email, description } = this.state;
    e.preventDefault();

    const template_params = {
      name,
      email,
      description
    };
    const user_id = process.env.REACT_APP_EMAILJS_USER_ID;
    const service_id = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const template_id = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    this.props.recaptcha.execute();
    this.setState({ formValid: false });
    if (this.props.recaptcha.getValue()) {
      emailjs
        .send(service_id, template_id, template_params, user_id)
        .then(res => {
          if (res.status === 200) {
            this.setState({ submitted: true, buttonText: "GET A QUOTE" });
            this.props.recaptcha.reset();
            this.setState({
              email: "",
              description: "",
              name: ""
            });
          }
        })
        .catch(() => {
          this.props.recaptcha.reset();
          this.setState({
            failed: true,
            email: "",
            buttonText: "GET A QUOTE"
          });
        });
    }

    this.setState({ showResponse: true }, () => {
      setTimeout(() => {
        this.setState({
          showResponse: false,
          failed: false,
          submitted: false
        });
      }, 10000);
    });
  };

  render() {
    const {
      name,
      nameError,
      nameErrorMsg,
      email,
      emailErrorMsg,
      emailError,
      description,
      descriptionErrorMsg,
      descriptionError,
      formValid,
      buttonText,
      showResponse,
      failed,
      submitted
    } = this.state;
    return (
      <div
        className={`quote-form-wrapper bg-navy animated ${this.props.classes}`}
        id="quote-form-home"
      >
        <form onSubmit={this.handleSubmit} className="quote-form">
          <div className="form-group mb-4">
            <input
              type="text"
              className="quote-inputs quote-input-fields form-control"
              placeholder="Enter Your Name"
              name="name"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={name}
            />
            {nameError && (
              <span className="error text-danger">{nameErrorMsg} </span>
            )}
          </div>
          <div className="form-group mb-4">
            <input
              type="text"
              className="quote-inputs quote-input-fields form-control"
              placeholder="Enter Your Email"
              name="email"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={email}
            />
            {emailError && (
              <span className="error text-danger">{emailErrorMsg} </span>
            )}
          </div>
          <div className="form-group mb-4">
            <textarea
              name="description"
              id="home-quote-desc"
              className="quote-inputs quote-description form-control"
              placeholder="Add Some Description"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={description}
            ></textarea>
            {descriptionError && (
              <span className="error text-danger">{descriptionErrorMsg} </span>
            )}
          </div>
          <div className="form-group mb-0">
            <button
              className="action-button btn-md-m bg-orange text-white text-bold"
              type="submit"
              disabled={!formValid}
            >
              {buttonText}
            </button>
          </div>
          {showResponse && (
            <div
              className={`form-group text-white text-center py-2 ${
                failed ? "bg-danger" : ""
              } ${submitted ? "bg-success" : ""}`}
            >
              {submitted
                ? "Your Quote was successfully sent."
                : failed
                ? "Sorry we were unable to send your quote. Please check your internet and try again."
                : ""}
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default QuoteForm;
