import React from "react";
import { Link } from "react-router-dom";

const Logo = () => (
  <div className="log-wrapper h-100">
    <Link to="/home" className="main-logo">
      abmo links
    </Link>
  </div>
);

export default Logo;
