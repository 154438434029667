import React, { Component } from "react";
import Aux from "../../hoc/Auxil";
import QuoteForm from "./QuoteForm";
import { TwoSplit } from "../layouts/Layouts";
import QuoteTextNext from "./QuoteTextNext";

const p1 = `We continuously strive towards 100% customer satisfaction in all 
aspects such as cost, efficiency and quality.We have attained the 
Safety and Site Management Certificates.This signifies the 
company’s commitment to continuously provide the best services 
and also to deliver high quality standards to its customers.`;

const p2 = `It is also the company’s policy to ensure timely handover, minimize
defects and material wastage, maintain a safe and healthy working
environment, and to provide constant training to its staff so as to 
improve their level of workmanship. You can thus rest assured that
your work shall be done professionally and efficiently.`;

class HomeQuote extends Component {
  state = {
    scroll: window.scrollY
  };

  componentDidMount() {
    window.addEventListener("scroll", this.scrollAnimate);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollAnimate);
  }

  scrollAnimate = () => {
    this.setState({ scroll: window.scrollY }, () => {
      const { scroll } = this.state;
      const qBox = document.querySelector("#quote-form-wrapper");
      const qForm = document.querySelector("#quote-form-home");
      const topDistance = qBox.scrollTop - scroll;

      if (
        window.innerWidth > 992 &&
        topDistance < -2750 &&
        topDistance > -3250
      ) {
        qForm.classList.add("swing");
      } else if (
        window.innerWidth >= 768 &&
        topDistance < -3250 &&
        topDistance > -3700
      ) {
        qForm.classList.add("swing");
      } else if (
        window.innerWidth >= 544 &&
        topDistance < -3200 &&
        topDistance > -3700
      ) {
        qForm.classList.add("swing");
      } else if (
        window.innerWidth >= 320 &&
        topDistance < -3050 &&
        topDistance > -3550
      ) {
        qForm.classList.add("swing");
      } else qForm.classList.remove("swing");
    });
  };

  render() {
    const quoteForm = {
      component: QuoteForm,
      props: {
        classes: "",
        recaptcha: this.props.recaptcha
      }
    };

    const quoteText = {
      component: QuoteTextNext,
      props: {
        title: "Tell Us About Your Project",
        paragraphs: [
          {
            text: p1,
            classes: ""
          },
          {
            text: p2,
            classes: ""
          }
        ]
      }
    };
    return (
      <Aux>
        <div className="my-container default-y-spacing" id="quote-form-wrapper">
          <TwoSplit half2={quoteForm} half1={quoteText} />
        </div>
      </Aux>
    );
  }
}

export default HomeQuote;
