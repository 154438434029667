import React from "react";

import HomeAboutLeft from "./HomeAboutLeft";

const button = {
  path: "/about",
  text: "Read More ABout Us",
  classes: "bg-orange btn-md-m btn-all text-md-bold"
};

const paragraph1 = (
  <>
    <strong>ABMO LINKS Limited </strong> was Founded in 2015. We have been active
    in most aspects of the construction industry. The company has worked on both
    public and private projects which range from building, water,
    telecommunication to civil engineering projects. We have been privileged to
    work with government bodies, statutory boards, private developers and home
    also owners.
  </>
);

const paragraph2 = `We specialize in telecommunication works, building works, civil
  engineering works, water works, addition and alteration works,
  upgrading works, design and build projects, and property
  development.`;

const paragraphs = [
  {
    text: paragraph1,
    classes: ""
  },
  {
    text: paragraph2,
    classes: ""
  }
];

const HomeAbout = () => (
  <div className="home-about-wrapper default-y-spacing my-container">
    <HomeAboutLeft title="About Us" paragraphs={paragraphs} button={button} />
  </div>
);

export default HomeAbout;
