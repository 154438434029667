import React from "react";

export const TwoSplit = ({ half1, half2 }) => (
  <div className="split-wrapper">
    <div className="row">
      <div className="col-md-6 col-12">
        <half1.component {...half1.props} />
      </div>
      <div className="col-md-6 col-12">
        <half2.component {...half2.props} />
      </div>
    </div>
  </div>
);
