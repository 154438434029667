import React from "react";

const DetailItem = ({ name, description }) => (
  <div className="detail-item-wrapper">
    <div className="project-detail-item project-detail-item-left txt-orange text-sm-bold">
      {name}
    </div>
    <div className="project-detail-item project-detail-item-right text-white">
      {description}
    </div>
  </div>
);

const ListDetail = ({ name, items }) => (
  <div className="detail-item-wrapper list-detail-item">
    <div className="project-detail-item project-detail-item-left txt-orange text-sm-bold">
      {name}
    </div>
    <div className="project-detail-item project-detail-item-right">
      {items.map((item, i) => (
        <div className="py-1 text-white" key={i}>
          {" "}
          - {item}
        </div>
      ))}
    </div>
  </div>
);

const ProjectDetails = ({ project }) => (
  <div className="project-details-wrapper bg-navy py-4">
    <DetailItem name="Name" description={project.fullName} />
    <DetailItem name="Status" description={project.status} />
    <DetailItem name="Expected End Date" description={project.endDate} />
    <DetailItem name="Objective" description={project.objective} />
    <DetailItem name="Budget" description={`Ksh. ${project.actualBudget}`} />
    <DetailItem name="Client" description={project.client} />
    <DetailItem name="Location" description={project.location} />
    <ListDetail name="Works Involved" items={project.typeOfWork} />
  </div>
);

export default ProjectDetails;
