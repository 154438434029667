import React from "react";

const CarouselControl = () => (
  <ol className="carousel-indicators">
    <li data-target="#home-carousel" data-slide-to="0" className="active"></li>
    <li data-target="#home-carousel" data-slide-to="1"></li>
  </ol>
);

export default CarouselControl;
