import React from "react";

import LandingItem from "./LandingItem";
import CarouselControl from "./CarouselControls";

import Checked from "../../assets/icons/select-white.svg";
import { SvgIcon } from "../icons/icon";

const P1Text = () => (
  <div className="car-text text-bold">
    <p className="sxl-txt text-white text-x-bold landing-head-text">
      We believe every building is as great as its constructor.
    </p>
  </div>
);

const P2Text = () => (
  <div className="car-text  text-white text-bold car-2-text">
    <p className="sxl-txt text-white text-x-bold landing-head-text">
      We are your BEST choice.
    </p>
    <div className="car-2-list">
      <ul className="car-2-check-list">
        <li className="landing-list-item text-md-bold md-md-txt text-white">
          <SvgIcon src={Checked} alt="checked-1" classes="md-img-icon pr-1" />{" "}
          High Quality Construction
        </li>
        <li className="landing-list-item text-md-bold md-md-txt text-white">
          <SvgIcon src={Checked} alt="checked-2" classes="md-img-icon pr-1" />{" "}
          100% Cost Efficient
        </li>
        <li className="landing-list-item text-md-bold md-md-txt text-white">
          <SvgIcon src={Checked} alt="checked-3" classes="md-img-icon pr-1" />{" "}
          100% Client Satisfaction
        </li>
      </ul>
    </div>
  </div>
);

const landingItems = [
  {
    component: P1Text,
    id: "car-img-1"
  },
  {
    component: P2Text,
    id: "car-img-2"
  }
];

const LandingPage = () => (
  <div className="landing-wrapper">
    <div className="carousel-wrapper">
      <div id="home-carousel" className="carousel slide" data-ride="carousel">
       <CarouselControl />
        <div className="carousel-inner">
          <div className="carousel-item active">
            <LandingItem
              imageId={landingItems[0].id}
              element={landingItems[0]}
              key={landingItems[0].id}
            />
          </div>
          <div className="carousel-item">
            <LandingItem
              imageId={landingItems[1].id}
              element={landingItems[1]}
              key={landingItems[1].id}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default LandingPage;
