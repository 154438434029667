import React, { useState } from "react";
import GoogleMapReact from "google-map-react";

import Marker from "./Marker";

const FooterItemRight = () => {
  const [center] = useState({ lat: -1.28592, lng: 36.820066 });
  const [zoom] = useState(15);

  return (
    <>
      <div className="map-box mb-3">
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          <Marker lat={-1.285977} lng={36.819354} />
        </GoogleMapReact>
      </div>
      <p className="footer-id-text txt-orange mb-1 pl-3">
        Room R2, 2nd Floor, Caxton House
      </p>
      <p className="footer-id-text txt-orange mb-1 pl-3">
        Standard Street, Nairobi
      </p>
      <p className="footer-id-text txt-orange mb-1 pl-3">P.0.BOX 43253-00100</p>
    </>
  );
};

export default FooterItemRight;
