import React from "react";

const Marker = props => (
  <div>
    <div className="pin bounce" title="Caxton Building, Standard Street" />
    <div className="pulse" />
  </div>
);

export default Marker;
