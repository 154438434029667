import React from "react";
import { IconUnderLinedTitle } from "../titles/Titles";

import gIcon from "../../assets/icons/group.svg";
import TeamItem from "./TeamItem";

import dir1 from "../../assets/images/directors/dir-1.jpg";
import dir2 from "../../assets/images/directors/dir-2.jpeg";
import dir3 from "../../assets/images/directors/dir-3.jpg";
import dir4 from "../../assets/images/directors/dir-4.jpg";

const members = [
  {
    name: "Jeremiah Omwoyo",
    rank: "CEO",
    image: dir1
  },
  {
    name: "John Amai",
    rank: "CPO",
    image: dir2
  },
  {
    name: "Daniel Bundi",
    rank: "CTO",
    image: dir3
  },
  {
    name: "Joash Omache",
    rank: "CFO",
    image: dir4
  }
];

const Team = () => (
  <div className="default-y-spacing team-members p-4">
    <IconUnderLinedTitle
      icon={gIcon}
      text="Meet The Team"
      lineCss="team-member-underline"
    />
    <div className="team-members-wrapper mt-5">
      {members.map(member => (
        <TeamItem member={member} key={member.name} />
      ))}
    </div>
  </div>
);

export default Team;
