import React from "react";

const TeamItem = ({ member }) => (
  <div className="team-member-card pb-2">
    <div className="image">
      <img
        src={member.image}
        alt={member.name}
        className="img-fluid team-member-image"
      />
    </div>
    <div className="team-member-name-box p-2 text-center">
      <p className="team-member-name mb-1">{member.name}</p>
      <span className="team-member-rank text-md-bold sm-txt">
        {member.rank}
      </span>
    </div>
  </div>
);

export default TeamItem;
