import React, { Component } from "react";

import Aux from "../../hoc/Auxil";
import Logo from "../../components/logo/Logo";
import Navbar from "../../components/navbar/Navbar";

class Header extends Component {
  state = {
    width: window.innerWidth,
    scroll: window.scrollY
  };

  componentDidMount() {
    window.addEventListener("resize", this.showNav);
    window.addEventListener("scroll", this.scrollHeader);
    this.setActiveOnPageLoad();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.showNav);
    window.removeEventListener("scroll", this.scrollHeader);
  }

  showNav = () => {
    this.setState({ width: window.innerWidth }, () => {
      const navBar = document.querySelector("#main-nav");
      if (this.state.width >= 873) {
        navBar.style.display = "block";
      } else {
        navBar.style.display = "none";
      }
    });
  };

  scrollHeader = () => {
    this.setState({ scroll: window.scrollY }, () => {
      const { width, scroll } = this.state;
      const header = document.querySelector(".main-header");

      if (width >= 873 && scroll >= 500) {
        header.classList.add("bg-navy");
      } else if (width >= 768 && scroll >= 400) {
        header.classList.add("bg-navy");
      } else if (width >= 544 && scroll >= 300) {
        header.classList.add("bg-navy");
      } else if (width >= 414 && scroll >= 300) {
        header.classList.add("bg-navy");
      } else if (width >= 320 && scroll >= 100) {
        header.classList.add("bg-navy");
      } else header.classList.remove("bg-navy");
    });
  };

  barsIconClick = e => {
    // get navbar
    const navbar = document.querySelector("#main-nav");
    // get blur
    const blurBox = document.querySelector("#bg-blur");

    const showNav = e.target.classList.contains("fa-bars");
    const header = document.querySelector(".main-header");
    // add header background if not available
    if (!header.classList.contains("bg-navy")) header.classList.add("bg-navy");
    // set blur to visible
    blurBox.style.display = showNav ? "block" : "none";
    // set navbar to visible
    navbar.style.display = showNav ? "block" : "none";
    // switch icon
    e.target.classList.toggle("fa-bars");
    e.target.classList.toggle("fa-times");
    // e.target.classList.toggle("phone-nav-icon-hide");
  };

  setActiveOnPageLoad = () => {
    const currentAddress = window.location.href.split("/")[3];
    const activeNav = document.getElementById(
      currentAddress === "" ? "home" : currentAddress
    );
    if (!activeNav) return 0;
    activeNav.classList.add("nav-active");
  };

  navLinkClick = e => {
    if (this.state.width < 873) {
      document.querySelector("#main-nav").style.display = "none";
      document.querySelector("#bg-blur").style.display = "none";
      const icon = document.querySelector(".phone-nav-icon");
      icon.classList.toggle("fa-bars");
      icon.classList.toggle("fa-times");
    }

    document.querySelector(".nav-active").classList.remove("nav-active");
    window.scrollTo(0, 0);
    e.target.classList.add("nav-active");
  };

  render() {
    return (
      <Aux>
        <header className="main-header fixed-top">
          <div className="header-row my-container">
            <Logo />
            <Navbar
              barsIconClick={this.barsIconClick}
              navLinkClick={this.navLinkClick}
            />
          </div>
        </header>
      </Aux>
    );
  }
}

export default Header;
