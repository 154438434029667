// Tomlo
import t0 from "../../assets/images/projects/display/tomlo.jpg";
import t1 from "../../assets/images/projects/tolmo/0.jpg";
import t2 from "../../assets/images/projects/tolmo/1.jpg";
import t3 from "../../assets/images/projects/tolmo/2.jpg";
import t4 from "../../assets/images/projects/tolmo/4.jpg";
import t5 from "../../assets/images/projects/tolmo/5.jpg";
import t6 from "../../assets/images/projects/tolmo/6.jpg";

// Simiyus
import s0 from "../../assets/images/projects/display/simiyu.jpg";
import s1 from "../../assets/images/projects/simuyus/0.jpeg";
import s2 from "../../assets/images/projects/simuyus/1.jpeg";
import s3 from "../../assets/images/projects/simuyus/2.jpeg";
import s4 from "../../assets/images/projects/simuyus/3.jpeg";
import s5 from "../../assets/images/projects/simuyus/4.jpeg";
import s6 from "../../assets/images/projects/simuyus/5.jpeg";
import s7 from "../../assets/images/projects/simuyus/6.jpeg";

// Nandi
// import n0 from "../../assets/images/projects/display/ngaaf.jpg";

// KCA
import k0 from "../../assets/images/projects/display/kca.jpg";
import k2 from "../../assets/images/projects/kca/1.jpeg";
import k3 from "../../assets/images/projects/kca/2.jpeg";
import k4 from "../../assets/images/projects/kca/3.jpeg";
import k5 from "../../assets/images/projects/kca/4.jpeg";
import k6 from "../../assets/images/projects/kca/5.jpeg";
import k7 from "../../assets/images/projects/kca/6.jpeg";
import k8 from "../../assets/images/projects/kca/7.jpeg";

const projects = [
  {
    id: 1,
    image: t0,
    images: [t1, t2, t3, t4, t5, t6],
    name: "Tolmo Water Project",
    type: "Water Works",
    budget: "5.22M",
    location: "Tolmo, Baringo County, Kenya",
    client: "Rift Valley Water Services Board",
    path: "Tomlo-Water-Project",
    fullName: "Proposed Construction Works for Tolmo Water Project; RVWSB",
    actualBudget: "5,222,843.16",
    status: "On Going",
    startDate: "",
    endDate: "November 30th, 2019",
    typeOfWork: [
      "Civil Works",
      "Masonry Tank",
      "Water Kiosks",
      "Pipeline Works"
    ],
    objective:
      "To supply piped and kiosk dispensed water to residents of Tolmo and surrounding villages, Baringo County."
  },
  {
    id: 2,
    image: s0,
    images: [s1, s2, s3, s4, s5, s6, s7],
    name: "The Simiyu's Residential Bungalow",
    type: "Building and Construction Works",
    budget: "17.0M",
    location: "Kwanza , Trans Nzoia County, Kenya",
    client: "Mr. & Mrs. Simiyu",
    path: "The-Simiyus-Residential-Bungalow",
    fullName: "Proposed Residential Bungalow; Mr&Mrs. E. Simiyu",
    actualBudget: "17,000,000.00",
    status: "On Going",
    startDate: "",
    endDate: "31st December, 2019",
    typeOfWork: [
      "Civil Works",
      "Structural Works",
      "Mechanical Works",
      "Electrical Works",
      "Landscaping Works"
    ],
    objective: "To establish a modern family residential unit."
  },
  {
    id: 3,
    image: k0,
    images: [k2, k3, k4, k5, k6, k7, k8],
    name: "KCA Library & Office Extensions",
    type: "Building and Construction Works",
    budget: "195.0M",
    location: "Kisii County, Kenya",
    client: "Kisii County Assembly",
    path: "Kisii-County-Assembly-Office-Extensions",
    fullName:
      "Proposed Extension of Library, ICT Centre, and Procurement Offices to host MCA Offices; Kisii County Assembly",
    actualBudget: "195,000,000.00  ",
    status: "On Going",
    startDate: "",
    endDate: "June 30th, 2020",
    typeOfWork: [
      "Civil Works",
      "Structural Works",
      "Mechanical Works",
      "Electrical Works",
      "S/Cabling Works"
    ],
    objective:
      "To create study space, communications centre, and office space for Procurement Officers and Members of the County Assembly."
  }
];

export default projects;
