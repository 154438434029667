import React from "react";

import Title from "../../components/titles/Titles";

const FooterItemLeft = () => (
  <>
    <Title
      text="Our Mission"
      classes="text-white footer-titles mission-title text-bold-m"
    />
    <p className="mission-text text-white mt-2">
      <i className="fas fa-quote-left txt-orange pr-2"></i>
      To be the undisputed innovative leader in Africa’s Construction and
      Telecoms Renaissance.
      <i className="fas fa-quote-right txt-orange pl-2"></i>
    </p>
  </>
);

export default FooterItemLeft;
