import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import Aux from "../../hoc/Auxil";
import TopBar from "../../components/topBar/TopBar";
import FeaturedProjects from "../../components/featuredProjects/Projects";
import HomeQuote from "../../components/homeQuote/HomeQuote";

import projects from "./projectData";

class Portfolio extends Component {
  componentDidMount() {
    if (this.recaptcha) {
      this.restartCaptcha();
    }
  }

  restartCaptcha = () => {
    this.recaptcha.reset();
  };
  render() {
    return (
      <Aux>
        <TopBar imageId="portfolio-bg" text="Our Portfolio" />
        <div className="about-nca-text-wrap default-y-spacing my-container services-into-text">
          <div className="m-0 nca-text">
            Throughout Kenya, ABMO-built projects keep abounding, representing
            various types of industries and applications. Many of our projects
            have become noted regional landmarks. The wide spectrum of ABMO
            projects reflects broad construction experience to apply to your
            project vision. ABMO LINKS’ past and current portfolio is valued at
            close to a billion Kenya Shillings. Notable clients include the
            Kisii County Assembly, Rift Valley Water Services Board, National
            Employment Authority, Ministry of Transport and Infrastructure, La
            Nyavu Gardens, and a host of Private Development Clients. Below are
            some of our top featured projects. More are coming to the website
            soon for our clients and prospects to witness ABMO-LINKS great
            works.
          </div>
        </div>
        <FeaturedProjects portfolio projects={projects} />
        <HomeQuote recaptcha={this.recaptcha} />
        <ReCAPTCHA
          ref={ref => (this.recaptcha = ref)}
          sitekey={process.env.REACT_APP_V2_CAPTCHA_SITE_KEY}
          size="invisible"
          render="explicit"
          onExpired={this.restartCaptcha}
        />
      </Aux>
    );
  }
}

export default Portfolio;
