import React from "react";
import Slider from "react-slick";

const ProjectImages = ({ images }) => {
  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    pauseOnHover: true,
    swipeToSlide: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  };

  const setDisplayImage = (e, image) => {
    const displayImage = document.querySelector(".display-image");
    const selectedImage = document.querySelector(".selected-image");
    selectedImage.classList.remove("selected-image");
    e.target.classList.add("selected-image");
    displayImage.setAttribute("src", image);
  };

  return (
    <div className="project-image-wrapper">
      <div className="row">
        <div className="col-12 display-box">
          <img className="display-image img-fluid" src={images[0]} alt="" />
        </div>
        <div className="col-11 mx-auto mt-3">
          <Slider {...settings} className="slider-sl">
            {images.map((image, index) => (
              <div
                className="col px-0 pr-1"
                key={index}
                onClick={e => setDisplayImage(e, image)}
              >
                <img
                  className={`mini-image`}
                  src={image}
                  alt=""
                  onLoad={e =>
                    index === 0 && e.target.classList.add("selected-image")
                  }
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ProjectImages;
